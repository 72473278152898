
import il8n from '@/assets/language/index.js';
const { t } = il8n.global;

// var validateUsername = (rule, value, callback) => {
//     let flag = new RegExp(/^([a-z\u4e00-\u9fa5\d]+?)$/).test(value)
//     if (!flag) {
//       callback(new Error(t('user.rules1')))
//     }
//     api_.judgeUserName(value).then(response => {
//       if (response.code !== 0 || response.code) {
//         callback(new Error(t('user.rules2')))
//       } else {
//         callback()
//       }
//     })
//   }
export const tableOption = ()=>{
    return {
        border: true,
        height: 'calc(100vh - 300px)',
        index: true,
        indexLabel: t('user.text1'),
        indexWidth: 100,
        menuAlign: 'center',
        // viewBtn: true,//查看按钮
        editBtn: false,
        delBtn: false,
        align: 'center',
        labelWidth: '110',
        columnBtn: false,
        addBtn: false,
        refreshBtn: false,
        menu:false,
    column: [{
        width: 100,
        label: t("currencySet.name1"),
        prop: 'tenantName',
    }, {
        width: 100,
      label: t('user.text2'),
      prop: 'username',
    }, {
        width: 250,
      label: t('user.text3'),
      prop: 'password',
    }, {
        width: 100,
      label: t('user.text5'),
      prop: 'name',
    }, {
        width: 150,
      label: t('user.text7'),
      prop: 'phone',
    },
      {
        width: 200,
        label:t('user.text9'),
        prop: 'role',
      }, 
      {
        width: 120,
        label: t('user.text11'),
        prop: 'email',
      }, {
        width: 160,
        label: t('user.text12'),
        prop: 'createTime',
      },{
        label: t('user.text10'),
        prop: 'lockFlag',
        width: 110,
        fixed:'right',
      },{
        width: 100,
        label: t("button.operation"),
        prop: 'caozuo',
        placeholder: t("inputTit.shuru"),
        fixed:'right',
      }]
    }
}