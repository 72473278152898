<template>
    <div class="user">
        <fromSearch :object_='object_' @searchFun='getList'/>
        <avue-crud ref="crud" :option="option"
            :page="page" :data="page.list"
            :table-loading="listLoading"
            @size-change="sizeChange"
            @current-change="currentChange">
            <template #menu-left>
                <el-button v-if="sys_user_add" class="filter-item"
                    type="primary" con="el-icon-edit" @click="openView(0)" >{{$t('button.add')}} </el-button>
                <!-- <el-button class="filter-item" plain
                    type="primary" size="small" icon="el-icon-upload" @click="show" >导入 </el-button> -->
                <el-button class="filter-item" plain type="primary"
                    @click="exportExcel" >{{$t('button.imports')}} </el-button>
            </template>
            <template #username="scope">
                <span>{{ scope.row.username }}</span>
            </template>
            <template #phone="scope">
                <span v-if='scope.row.phone'>{{ '+' + scope.row.phone }}</span>
            </template>
            <template #role="scope">
                <span v-for="(role, index) in scope.row.roleList" :key="index">
                    <el-tag>{{ role.roleName }} </el-tag>&nbsp;&nbsp;
                </span>
            </template>
            <template #post="scope">
                <span v-for="(role, index) in scope.row.postList" :key="index">
                    <el-tag>{{ role.postName }} </el-tag>&nbsp;&nbsp;
                </span>
            </template>
            <!-- <template #deptId="scope">
                {{ scope.row.deptName }}
            </template> -->
            <template #lockFlag="scope">
                <el-switch v-model="scope.row.lockFlag" :inactive-value='valueArray[1].value' :active-value='valueArray[0].value' @change='enableFun(scope.row)'/>
            </template>
            <template #caozuo="scope">
                <el-button
                    v-if="sys_user_edit" text type="primary"
                    size="small"
                    @click="openView(1,scope.row)" >{{$t('button.update')}} </el-button>
                <el-button
                    v-if="sys_user_del" text type="primary"
                    size="small"
                    @click="rowDel(scope.row, scope.index)" >{{$t('button.del')}} </el-button>
            </template>
            <template #createTime="scope">
                {{turnTimeFun(scope.row.createTime)}}
            </template>
        </avue-crud>
        <el-dialog v-model="dialogFormVisible" :title='isStyleText[isStyle]' width="600">
            <bindView ref='bindView_' @successFun='successFun'/>
            <template #footer>
                <el-button    @click="dialogFormVisible = false">{{$t('button.closeQ')}} </el-button>
                <el-button type="primary"   @click="completeFun">{{$t('button.tijiao')}}</el-button>
            </template>
        </el-dialog>
    </div>
</template>
<script setup>
    import { ref,nextTick,getCurrentInstance } from 'vue'
    import Api_ from '@/api/index'
    import { tableOption } from "@/const/crud/admin";
    import { downBlobFile } from '@/utils/util'
    import bindView from './bind.vue'
    import mixins from '@/views/mixins/page'
    import fromSearch from '@/components/fromSearch.vue'
    import { user } from '@/const/from/platform/user'
    import { ElNotification } from 'element-plus'
    import { turnTimeFun } from '@/utils/util.js'
    import { getDataStyle } from '@/utils/dataBind/parmsView'  

    const { $t } = getCurrentInstance().proxy;
    let pageObj = mixins(['adminUser',['delUser','userId']])
    const {page,dialogFormVisible,listLoading,sizeChange,currentChange,getList,rowDel,successFun} = pageObj
    const isStyle = ref(0)
    const isStyleText = [$t("button.add"),$t("button.update")]
    const valueArray = ref([])
    getDataStyle('status_type',(e)=>{valueArray.value = e})

    const crud = ref(null)
    const treeData = ref([])
    const roleArray = ref([])
    const option = ref(null)
    const object_ = ref(user);
    //用户权限
    const sys_user_add = ref(true)
    const sys_user_edit = ref(true)
    const sys_user_del = ref(true)
    option.value = tableOption([])
    const bindView_ = ref(null)

    let searchForm = {}

    const enableFun = (e)=>{
        if(e.userId){
            let s_ = e.lockFlag
            listLoading.value = true
            Api_.updateUser({
                userId: e.userId,
                lockFlag: s_,
                username: e.username
            }).then(res=>{
                listLoading.value = false
                if(res.code === 0){
                    ElNotification({
                        message: e.lockFlag === '0'?$t('alert.succOpen'):$t('alert.succOff'),
                        type: 'success',
                    })
                    getList()
                }else{
                    ElNotification({
                        message: res.msg,
                        type: 'error',
                    })
                    e.lockFlag = !e.lockFlag
                }
            })
        }
    }
    const openView = (num,parm)=>{
        isStyle.value = num
        dialogFormVisible.value = true
        nextTick(()=>{
            bindView_.value.init_(num,parm,getDatalist(treeData.value),roleArray.value)
        })
    }
    Api_.treeApi().then(res=>{//获得部门数据
        if(res.code === 0){
            treeData.value = res.data
        }
    }) 
    Api_.roleListApi().then(res=>{
        if(res.code === 0){
            roleArray.value = res.data
        }
    })
    getList(1)
    
    const completeFun = ()=>{
        bindView_.value.completeFun()
    }
    const exportExcel = ()=>{//点击下载
        downBlobFile('/admin/user/export',"user.xlsx",searchForm)
    }
    const getDatalist = (obj_)=>{
        let arr_ = []
        for(let index in obj_){
            let obj_1 = {}
            obj_1.value = obj_[index].id
            obj_1.label = obj_[index].name
            if(obj_[index].children && obj_[index].children.length > 0){
                obj_1.children = getDatalist(obj_[index].children)
            }
            arr_.push(obj_1)
        }
        return arr_
    }
</script>
