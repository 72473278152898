import il8n from '@/assets/language/index.js';
const { t } = il8n.global;

export const user = [{
    type: 'select',
    name: t("inface.text3"),
    prop: 'tenantId',
    isPostApi: 'shopList',
    isPlatform: 1,//平台显示，商户不显示
    props:{
        value:'id',
        label:'name'
    },
},{
    type: 'input',
    name: t('user.text2'),
    prop: 'username',
},]